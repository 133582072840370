const paths = {
    not_found: '/not-found',
    welcome: '/inicio',
    home: '/home',
    login: '/login',
    invitation: '/participar',
    passwordRecovery: '/recuperar-senha',
    passwordReset: '/redefinir-senha',
    register_preferences: '/preferencias',
    secret_santa: {
        add: '/criar-amigo-secreto',
        detail: '/detalhes-do-amigo-secreto'
    },
    products: {
        list: '/presentes-sugeridos',
        detail: '/detalhes-do-produto',
        available_stores: '/lojas-disponiveis'
    },
    appointments: {
        list: '/sessoes',
        detail: '/sessoes/detalhes'
    },
    register: '/cadastro'
    // register: {
    //     base: '/cadastro',
    // info: '/cadastro/informacoes',
    // nickname: '/cadastro/apelido',
    // children: {
    //     info: 'informacoes',
    //     nickname: 'apelido',
    // }
    // }
}

export default paths