
interface String {
    capitalizeFirst(): String;
    limitString(limit?: number): String;
    clear(): String;
    currencyToNumber(brl?: boolean): number;
    removeAccents(): String;
}
String.prototype.capitalizeFirst = function () {
    let res = this.toLowerCase()
    try {
        res = res.charAt(0).toUpperCase() + res.slice(1);
    } catch (e) {
        console.log(e)
    } finally {
        return res
    }
}
String.prototype.limitString = function (limit = 40) {
    let res = this
    try {
        res = this.length >= limit ? `${this.substr(0, limit - 1)}...` : this
    } catch (e) {
        console.log('erro', e)
        return res
    }
    finally {
        return res
    }
}
String.prototype.clear = function () {
    let res = this
    try {
        res = this.replace(/[^\w\s]/gi, '').replace(' ', '').replace(/[_-]/g, "")
    } catch (e) {
        return res
    }
    finally {
        return res
    }
}
String.prototype.currencyToNumber = function (brl = true) {
    let res = this
    try {
        res = this.replace(brl ? 'R$ ' : '$ ', '').replace(',', '.')
    } catch (e) {
        return 0
    }
    finally {
        return Number(res)
    }
}
String.prototype.removeAccents = function () {
    let res = this
    let reg = /[\u0300-\u036f]/g
    try {
        res = this.normalize('NFD').replace(reg, '')
    } catch (e) {
        return res
    }
    finally {
        return res
    }
}

