export const fakeSession = {
    id: 2,
    name: 'Andrews Alves',
    email: 'email@teste.com'
}
export const fakeCategoryList = [
    {
        id: 2,
        name: 'Categoria 1',
    },
    {
        id: 21,
        name: 'Teste',
    },
    {
        id: 22,
        name: 'Maçã',
    },
    {
        id: 23,
        name: 'Mercado',
    },
    {
        id: 24,
        name: 'Itália',
    },
    {
        id: 25,
        name: 'Pessego',        
    },
    {
        id: 26,
        name: 'Manivela',
    },
    {
        id: 7,
        name: 'Macaco',
    },
]