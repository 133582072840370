import '@/assets/theme/theme.scss';
import UserService from '@/services/UserService';
import '@/utils/Prototypes/prototypeFunctions.js';
import '@popperjs/core';
import 'bootstrap';
import "moment/locale/pt-br";
import { createPinia } from 'pinia';
import money from 'v-money3';
import { createApp } from 'vue';
import * as pkg from '../package.json';
import App from './App.vue';
import './assets/js/jquery.js';
import ConfirmationAlert from './components/ConfirmationAlert/ConfirmationAlert.vue';
import Container from './components/Container.vue';
import EmptyState from './components/EmptyState.vue';
import AutocompleteInput from './components/FormComponents/AutocompleteInput/AutocompleteInput.vue';
import FormButton from './components/FormComponents/FormButton.vue';
import MoneyInput from './components/FormComponents/MoneyInput.vue';
import PasswordInput from './components/FormComponents/PasswordInput.vue';
import RadioInput from './components/FormComponents/RadioInput.vue';
import SelectInput from './components/FormComponents/SelectInput.vue';
import TextAreaInput from './components/FormComponents/TextAreaInput.vue';
import TextInput from './components/FormComponents/TextInput.vue';
import ZipCodeInput from './components/FormComponents/ZipCodeInput/ZipCodeInput.vue';
import Header from './components/Header.vue';
import LoadingContainer from './components/LoadingContainer.vue';
import ModalComponent from './components/ModalComponent.vue';
import Navbar from './components/Navbar.vue';
import SearchBar from './components/SearchBar.vue';
import SelectSearchContainer from './components/SelectSearchContainer.vue';
import TableHead from './components/TableHead.vue';
import router from './router';
import paths from './router/paths';
import ProductService from './services/ProductService';
import SecretSantaService from './services/SecretSantaService';
import { EventBus } from "./utils/EventBus";
import PaginationComponent from './components/PaginationComponent.vue';


const pinia = createPinia()
const app = createApp(App);

app.config.globalProperties.$userService = new UserService()
app.config.globalProperties.$productService = new ProductService()
app.config.globalProperties.$secretSantaService = new SecretSantaService()
app.config.globalProperties.$paths = paths;
app.config.globalProperties.$eventBus = EventBus;
app.config.globalProperties.$appString = pkg.version;


app.component("Navbar", Navbar);
app.component("LoadingContainer", LoadingContainer);
app.component("EmptyState", EmptyState);
app.component("Container", Container);
app.component("MoneyInput", MoneyInput);
app.component("SearchBar", SearchBar);
app.component("TextInput", TextInput);
app.component("RadioInput", RadioInput);
app.component("SelectInput", SelectInput);
app.component("TextAreaInput", TextAreaInput);
app.component("PasswordInput", PasswordInput);
app.component("FormButton", FormButton);
app.component("TableHead", TableHead);
app.component("ModalComponent", ModalComponent);
app.component("Header", Header);
app.component("ConfirmationAlert", ConfirmationAlert);
app.component("AutocompleteInput", AutocompleteInput);
app.component("SelectSearchContainer", SelectSearchContainer);
app.component("ZipCodeInput", ZipCodeInput);
app.component("Pagination", PaginationComponent);


app.use(pinia).use(router).use(money).mount('#app')
