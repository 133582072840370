import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["disabled", "id"]
const _hoisted_2 = {
  key: 0,
  class: "spinner-border spinner-border-sm me-3",
  role: "status",
  "aria-hidden": "true"
}
const _hoisted_3 = { class: "text-action text-white" }
const _hoisted_4 = {
  key: 0,
  class: "position-absolute shadow-lg bg-danger-soft rounded error-container",
  style: {"top":"-50px"}
}
const _hoisted_5 = { class: "popover-header text-danger p-3 mb-0 fw-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "position-relative justify-content-center d-flex",
    onMouseover: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onMouseOver($event))),
    onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onMouseLeave($event)))
  }, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.clicked()), ["prevent"])),
      disabled: _ctx.loading,
      class: _normalizeClass(["btn w-100 btn-primary my-3", _ctx.btnClass]),
      id: _ctx.id
    }, [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.label), 1)
    ], 10, _hoisted_1),
    (_ctx.showError && _ctx.fieldError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, [
            _cache[3] || (_cache[3] = _createElementVNode("i", { class: "fa-solid fa-exclamation-circle" }, null, -1)),
            _createTextVNode(" " + _toDisplayString(_ctx.fieldError), 1)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 32))
}