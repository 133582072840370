<template>
  <Navbar />
  <ConfirmationAlert />
  <NotificationToast />
  <ErrorAlertComponent />
  <TermsModal />
  <router-view />
</template>

<script>
import ErrorAlertComponent from "@/components/ErrorAlertComponent.vue";
import NotificationToast from "@/components/NotificationToast.vue";
import TermsModal from "@/components/TermsModal.vue";
import { defineComponent } from "vue";

export default defineComponent({
  mounted() {
    /**
     * //TODO: 
     * ⁃	o group que vem no get de products é o nome da linha ? ( eu considerei que sim no front)
  ⁃	o price_group_string dos produtos não ta vindo formatado certo ( ta sem o cifrão e com uma casa decimal só)
  ⁃	testar o search e o endpoint das lojas quando migrar pro db de dev
  ⁃	falta o endpoint de show de produto
  ⁃	o user que ta vindo no objeto drawn no show de secret santa é o meu mesmo, não o do user que eu tirei
     */
    // if (useMainStore().session) {
    //   this.$userService.syncSession()
    // }
    // startFirebase()
  },
  created() {
    console.log('VERSION', `${this.$appString}`)
    document.title = 'Amigo Secreto Natura'
  },
  components: {
    ErrorAlertComponent,
    NotificationToast,
    TermsModal
  },
});
</script>

<style>
body {
  background-color: #fff !important;
}
</style>
