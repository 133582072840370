import { useMainStore } from "@/store";
import { fakeSession } from '@/utils/fakeData';
import NetworkService, { Endpoint } from "./NetworkService";
import { ISecretSantaPost } from "@/interfaces";
import moment from "moment";
export default class SecretSantaService extends NetworkService {

  constructor() {
    super();
  }

  static ROLES = {
    ADMIN: 'owner',
    PARTICIPANT: 'participant',
  }
  static STATUS = {
    PENDING: 'pending',
    DRAWN: 'drawn',
  }
  async create(params: ISecretSantaPost) {
    return this.post(Endpoint.secret_santas, params);
  }
  async list(params: any = {}) {
    let res = await this.get(Endpoint.secret_santas, params);
    res.data = res.data.map((item: any) => {
      return this.getFormatted(item)
    })
    return res;
  }
  async show(id: string) {
    let res = await this.get(`${Endpoint.secret_santas}/${id}`, {});
    res.data = this.getFormatted(res.data)
    return res;
  }

  async checkIfUserIsPartOfGroup(secret_santa_id: string) {
    try {
      const res = await this.get(`${Endpoint.secret_santas}/${secret_santa_id}/check`);
      return res.data.is_participant
    } catch (e) {
      return false
    }
  }
  join(secret_santa_id: string) {
    return this.post(`${Endpoint.secret_santas}/${secret_santa_id}/join`, {});
  }
  removeParticipant(secret_santa_id: string, participant_id: string){
    return this.post(`${Endpoint.secret_santas}/${secret_santa_id}/remove/${participant_id}`, {});
  }
  draw(secret_santa_id: string) {
    return this.post(`${Endpoint.secret_santas}/${secret_santa_id}/draw`, {});
  }

  getStatus(status: string): any {
    const st: any = {
      [SecretSantaService.STATUS.PENDING]: {
        text: 'Aguardando',
        bg: 'bg-primary'
      },
      [SecretSantaService.STATUS.DRAWN]: {
        text: 'Sorteado',
        bg: 'bg-success'
      },
    }

    return st[status]
  }

  getFormatted(item: any) {
    const session = useMainStore().session
    try {
      let formatted_object: any = {}
      formatted_object.date = `Criado no dia ${moment(item.created_at).format('DD [de] MMMM [de] YYYY')}`
      formatted_object.drawDate = item.drawn ? `Sorteio realizado no dia ${moment(item.drawn.created_at).format('DD [de] MMMM')}` : ''
      formatted_object.status = this.getStatus(item.status)
      formatted_object.iAmAdmin = !!(item.participants.find((elem: any) => elem.role == SecretSantaService.ROLES.ADMIN && elem.user_id === session.id))
      formatted_object.participants = item.participants?.sort((a: any, b: any) => {
        if (a.role === SecretSantaService.ROLES.ADMIN) {
          return -1
        }
        if (b.role === SecretSantaService.ROLES.ADMIN) {
          return 1
        }
        return 0
      }).map((elem: any) => {
        let splitName = elem.user.name.split(' ')
        return {
          id: elem.user_id,
          initials: `${splitName[0].charAt(0)}${splitName[1].charAt(0)}`,
          name: elem.user.name,
          isMe: session.id === elem.user_id,
          role: elem.role,
          date: moment(elem.created_at).format('DD [de] MMMM')
        }
      })
      item.formatted_object = formatted_object


    } catch (e) {
      console.log(e);
    } finally {
      return item;
    }
  }
}
