<template>
  <ModalComponent title="Termos de uso" :id="'terms-modal'" :modalDialogClass="'modal-dialog-scrollable'">
    <template v-slot:body>
      <div class="text-dark">
        <h4>
          Política de Privacidade
        </h4>
        <p>
          A sua privacidade é muito importante para nós. Esta Política de Privacidade descreve como coletamos, usamos,
          protegemos e compartilhamos as informações pessoais que você nos fornece ao acessar ou utilizar o nosso site.
        </p>
        <h4>
          1. Informações Coletadas
        </h4>
        <p>
          Coletamos informações pessoais que você nos fornece diretamente, como quando se registra em nosso site,
          preenche
          formulários ou entra em contato conosco. As informações podem incluir nome, e-mail, e outros dados que você
          decidir compartilhar.
        </p>
        <p>
          Além disso, podemos coletar automaticamente informações sobre o seu dispositivo e a sua navegação, como o
          endereço
          IP, tipo de navegador, páginas visitadas e data e hora de acesso, por meio de cookies.
        </p>
        <h4>
          2. Como Usamos suas Informações
        </h4>
        <p>
          Utilizamos as informações coletadas para:
        </p>
        <ul>
          <li>
            Oferecer e personalizar nossos serviços;
          </li>
          <li>
            Enviar atualizações e promoções relacionadas aos nossos produtos e serviços;
          </li>
          <li>
            Melhorar a experiência de navegação no nosso site;
          </li>
          <li>
            Cumprir com obrigações legais.
          </li>
        </ul>
        <h4>
          3. Proteção de Dados
        </h4>
        <p>
          Adotamos medidas de segurança razoáveis para proteger suas informações pessoais contra acesso não autorizado,
          alteração, divulgação ou destruição.
        </p>
        <h4>
          4. Compartilhamento de Informações
        </h4>
        <p>
          Não compartilhamos suas informações pessoais com terceiros, exceto quando necessário para cumprir com
          obrigações
          legais ou quando prestadores de serviços, como hospedagem ou ferramentas de marketing, precisam de acesso para
          realizar suas funções. Esses terceiros são obrigados a manter a confidencialidade das suas informações.
        </p>
        <h4>
          5. Seus Direitos
        </h4>
        <p>
          Você tem o direito de acessar, corrigir, excluir ou bloquear suas informações pessoais. Caso deseje exercer
          esses
          direitos, entre em contato conosco por meio de nosso e-mail de contato.
        </p>
        <h4>
          6. Cookies
        </h4>
        <p>
          Nosso site utiliza cookies para melhorar a sua experiência de navegação. Você pode optar por desativar os
          cookies
          nas configurações do seu navegador, mas isso pode afetar a funcionalidade do site.
        </p>
        <h4>
          7. Alterações
        </h4>
        <p>
          Podemos atualizar esta Política de Privacidade periodicamente. Qualquer alteração será publicada nesta página
          com
          a data da última atualização.
        </p>
        <hr>
        <h4>
          Termos de Uso
        </h4>
        <p>
          Estes Termos de Uso regem o acesso e a utilização do nosso site. Ao utilizar o site, você concorda com os
          seguintes termos:
        </p>
        <h4>
          1. Uso do Site
        </h4>
        <p>
          Você concorda em utilizar o site de acordo com a legislação aplicável e não realizar atividades ilícitas ou
          prejudiciais à segurança do site ou de outros usuários.
        </p>
        <h4>
          2. Propriedade Intelectual
        </h4>
        <p>
          Todo o conteúdo do site, incluindo textos, imagens, gráficos e logotipos, é protegido por direitos autorais e
          outras leis de propriedade intelectual. Você não pode reproduzir, distribuir ou modificar esse conteúdo sem a
          devida autorização.
        </p>
        <h4>
          3. Responsabilidade
        </h4>
        <p>
          Embora nos esforcemos para manter o site seguro e funcional, não nos responsabilizamos por danos diretos ou
          indiretos que possam resultar do uso do site ou da impossibilidade de acesso ao conteúdo.
        </p>
        <h4>
          4. Alterações
        </h4>
        <p>
          Podemos alterar estes Termos de Uso a qualquer momento, e as alterações serão publicadas nesta página. Ao
          continuar a utilizar o site após qualquer modificação, você concorda com os novos termos.
        </p>
        <h4>
          5. Legislação Aplicável
        </h4>
        <p>
          Estes Termos de Uso são regidos pelas leis do país e qualquer disputa relacionada ao uso do site será
          resolvida
          nos tribunais competentes dessa jurisdição.
        </p>
      </div>

    </template>
  </ModalComponent>
</template>
<style>
h4 {
  font-weight: bold;
}
</style>
