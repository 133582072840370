import { useMainStore } from '@/store';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import paths from './paths';
const routes: Array<RouteRecordRaw> = [
  {
    path: `${paths.welcome}`,
    name: 'Welcome',
    alias: '/',
    component: () => import('@/views/Welcome/Welcome.vue'),
  },
  {
    path: `${paths.home}`,
    name: 'Home',
    component: () => import('@/views/Home/Home.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.secret_santa.detail}/:id`,
    name: 'Secret Santa Detail',
    props: true,
    component: () => import('@/views/SecretSantaDetail/SecretSantaDetail.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.secret_santa.add}`,
    name: 'Add Secret Santa',
    component: () => import('@/views/SecretSantaAdd/SecretSantaAdd.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.products.list}/:secret_santa_id`,
    name: 'Products',
    props: true,
    component: () => import('@/views/Products/Products.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.products.available_stores}/:id`,
    name: 'Available Stores',
    props: true,
    component: () => import('@/views/Products/AvailableStores/AvailableStores.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.products.detail}/:id`,
    name: 'Product Detail',
    props: true,
    component: () => import('@/views/Products/ProductDetail/ProductDetail.vue'),
  },
  {
    path: `${paths.register_preferences}/:secret_santa_id`,
    name: 'Register Preferences',
    props: true,
    component: () => import('@/views/RegisterPreferences/RegisterPreferences.vue'),
  },

  {
    path: `${paths.login}`,
    name: 'Login',
    component: () => import('@/views/Login/Login.vue')
  },

  {
    path: `${paths.invitation}/:secret_santa_id`,
    name: 'Invitation',
    props: true,
    component: () => import('@/views/Login/Login.vue')
  },

  {
    path: `${paths.register}/:secret_santa_id?`,
    props: true,
    name: 'Register',
    component: () => import('@/views/Register/Register.vue')
  },
  {
    path: `${paths.passwordReset}`,
    name: 'Password Reset',
    component: () => import('@/views/PasswordReset/PasswordReset.vue'),
  },
  {
    path: paths.not_found,
    name: 'NotFound',
    component: () => import('@/views/NotFound/NotFound.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: {
      name: 'NotFound'
    }
  },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
})

router.beforeEach((to: any, from: any, next) => {
  const store = useMainStore()
  const isLoggedIn = !!store.session;

  if (to.path == '/') {
    if (isLoggedIn) {
      next(paths.home);
    } else {
      next(paths.welcome);
    }
  } else {
    if (to.meta.requiresAuth && !isLoggedIn) {
      next(paths.login);
    } else {
      next();
    }
  }


});




export default router
