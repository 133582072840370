import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "row"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "m-0" }
const _hoisted_4 = { class: "fw-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingContainer = _resolveComponent("LoadingContainer")!

  return (_ctx.loadingItems)
    ? (_openBlock(), _createBlock(_component_LoadingContainer, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredItems, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "cursor-pointer col-6 col-lg-4 align-items-center d-flex",
            key: index,
            onClick: ($event: any) => (_ctx.handleItemClicked(item))
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["flex-1 text-center rounded-4 p-5 my-3 border", { 'bg-primary-soft': _ctx.selectedItems.find(elem => elem.id == item.id) }])
            }, [
              _createElementVNode("h5", _hoisted_3, [
                _cache[0] || (_cache[0] = _createTextVNode(" Linha")),
                _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
                _createElementVNode("span", _hoisted_4, _toDisplayString(item.name.capitalizeFirst()), 1)
              ])
            ], 2)
          ], 8, _hoisted_2))
        }), 128))
      ]))
}