export function sortCols(columns: Array<any>, colIndex: string | number) {
    try {
        return columns.map((r, index) => {
            let order = null
            let loading = false
            if (colIndex == index) {
                loading = true
                order = r.order == null ? 'ASC' : r.order == 'ASC' ? 'DESC' : null
            }
            const l = {
                ...r,
                order,
                loading

            }
            return l
        })
    } catch (e) {
        console.log(e)
        return columns
    }
}

/**
 * 
 * @param {*} originArray array de itens em que deseja procurar 
 * @param {*} properties qual property do obj deseja comparar a string, se for vazio, busca em todas q forem string ou number
 * @param {*} string  a string de busca
 * @returns retorna o mesmo arrray mas com uma propriedade hidden, pra esconder da lista
 */
export function searchString(originArray: any[], string: string, ...keys: string[]) {
    try {
        let properties = keys
        if (!string) {
            originArray.map(r => r.hidden = false)
            return originArray
        }
        if (!originArray || originArray.length == 0) return originArray
        if (!properties || properties.length == 0) {
            properties = Object.keys(originArray[0])
        }
        var resultArray = originArray
        resultArray.map(item => {
            let reg = /[\u0300-\u036f]/g
            var itemFound = false
            properties.map(s => {
                if (item[s] && ['string', 'number'].includes(typeof item[s])) {
                    if (item[s].toString().toLowerCase().normalize('NFD').replace(reg, '').indexOf(string.toLowerCase().normalize('NFD').replace(reg, '')) > -1) {
                        itemFound = true
                    }
                }
            })
            item.hidden = !itemFound
        })
        return resultArray
    } catch (e) {
        console.log(e)
        return originArray
    }
}