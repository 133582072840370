import paths from "@/router/paths";
import NetworkService, { Endpoint } from "./NetworkService";
export default class ProductService extends NetworkService {

  constructor() {
    super();
  }

  async list(secret_santa_id: string) {
    let res = await this.get(`${Endpoint.products}/${secret_santa_id}`)
    let lines: any = []
    res.data.map((elem: any) => {
      if (!lines.find((line: any) => line.id == elem.line_id)) {
        lines.push({
          id: elem.line_id,
          group: elem.line.name
        })
      }
    })
    lines = lines.map((line: any) => {
      return {
        ...line,
        products: res.data.filter((product: any) => product.line_id == line.id).map((p: any) => this.getFormattedProduct(p))
      }
    })
    res.data = lines
    return res
  }
  async listStores(params: any = {}) {
    if (params.zipcode) {
      params.zipcode = params.zipcode.replace('-', '')
    }
    let res = await this.get(Endpoint.stores, params)
    res.data = res.data.map((elem: any) => {
      return this.getFormattedStore(elem)
    })
    return res
  }
  listLines() {
    return this.get(Endpoint.product_lines)
  }
  async show(id: string) {
    let res = await this.get(`${Endpoint.products}/${id}/info`)
    res.data = this.getFormattedProduct(res.data)
    return res
  }
  getFormattedProduct(item: any) {
    try {
      item.formatted_image = `${process.env.VUE_APP_IMG_BASE_URL}${item.picture}`
      item.formatted_price = ''
      if (item.price_direction == 'lower_than') {
        item.formatted_price = `Até ${item.price.toCurrencyString()}`
      } else {
        item.formatted_price = `Acima de ${item.price.toCurrencyString()}`
      }
    } catch (e) {
      console.log(e);
    } finally {
      return item
    }
  }
  getFormattedStore(item: any) {
    try {
      item.formatted_address = `${item.address} ${item.city.capitalizeFirst()} - ${item.state.toUpperCase()}`.limitString(70)
      item.formatted_name = item.company_name || item.name
      item.whatsapp_redirect = (productName: string, productId: string) => {
        const productPath = `${window.location.origin}${paths.products.detail}/${productId}`
        const text = `Olá! Estou no site Amigo Secreto Natura e gostaria de saber sobre a disponibilidade do produto: ${productName} ${productPath},  na loja ${item.formatted_name}. Poderiam me passar mais informações?`
        return `https://api.whatsapp.com/send?text=${text}&phone=+55${item.phone}`
      }

      if (item.distance) {
        let d
        if (item.distance < 1) {
          d = item.distance * 1000
          d = d.toFixed(0)
          item.formatted_distance = `${d} m`
        } else {
          d = item.distance
          d = d.toFixed(1)
          item.formatted_distance = `${d} km`
        }
      }


    } catch (e) {
      console.log(e);
    } finally {
      return item
    }
  }

}
