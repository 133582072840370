<template>
  <div class="header mt-md-5 m-0" :class="headerClass">
    <div class="header-body pb-2 border-0" :class="headerBodyClass">
      <div class="row align-items-center mt-5">
        <div class="col">
          <h1 class="header-title align-items-center d-flex">
            <span class="me-3 text-pagetitle header-text">{{ title }}</span>
          </h1>
          <span class="me-3 text-action" v-if="subtitle">{{ subtitle }}</span>
          <div>
            <p class="me-3 text-label text-dark mt-2">{{ infotitle }}</p>
          </div>
        </div>
        <div class="col-auto">
          <slot name="button"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  props: {
    title: String,
    subtitle: String,
    infotitle: String,
    btnLabel: String,
    btnLink: String,
    btnAction: Function,
    tabClicked: Function,
    btnIcon: String,
    tabs: Array,
    iconClass: String,
    headerClass: String,
    headerBodyClass: String,
    subtitle: String,
    totalCount: Number,
    isSubHeader: {
      type: Boolean,
      default: true
    },
    col: {
      type: String,
      default: 'col-10'
    }
  },
  methods: {
    handleBtnAction() {
      this.$emit('btnAction')
    },
    handleTabClicked(id) {
      this.$emit('tabClicked', id)
    }
  }
}
</script>

<style>
.header-subtitle-custom {
  font-size: 1.4rem !important;
}
</style>
