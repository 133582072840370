<template>
  <div class="row">
    <div class="col-12 d-flex justify-content-center">
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item cursor-pointer" :class="{ 'disabled': _currentPage == 1 }"><a class="page-link"
              @click.prevent="handlePrevious"><span class="fas fa-chevron-left text-primary"></span></a>
          </li>

          <template v-if="firstPageBeforeCurrent > 2">
            <li class="page-item cursor-pointer"><a class="page-link" @click.prevent="handlePageClicked(1)">{{ 1 }}</a>
            </li>
            <li class="page-item"><a class="page-link" @click.prevent="">...</a>
            </li>
          </template>

          <li v-for="p in pagesArray" class="page-item cursor-pointer"><a class="page-link"
              :class="{ 'active': _currentPage == p }" @click.prevent="handlePageClicked(p)">{{ p }}</a></li>

          <template v-if="lastPageAfterCurrent != maxPage">
            <li v-if="lastPageAfterCurrent < maxPage - 1" class="page-item"><a class="page-link"
                @click.prevent="">...</a>
            </li>
            <li class="page-item cursor-pointer"><a class="page-link" @click.prevent="handlePageClicked(maxPage)">{{
              maxPage
                }}</a>
            </li>
          </template>

          <li class="page-item cursor-pointer" :class="{ 'disabled': _currentPage == maxPage }"><a class="page-link"
              @click.prevent="handleNext"><span class="fas fa-chevron-right text-primary"></span></a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['pageChanged'],
  props: {
    maxPage: {
      type: Number,
      default: 10
    },
    pagesDisplayed: {
      type: Number,
      default: 5
    }
  },
  mounted() {
    this._currentPage = this.getPageFromUrl()
    this.$watch('$route.query', (newValue, oldValue) => {
      this._currentPage = this.getPageFromUrl()
    }, {
      deep: true,
    })
  },
  data() {
    return {
      _currentPage: 1
    }
  },
  watch: {
    _currentPage() {
      this.$emit('pageChanged', this._currentPage)
    },
  },
  computed: {
    firstPageBeforeCurrent() {
      let value = this._currentPage > this.pagesDisplayed ? this._currentPage - (Math.ceil(this.pagesDisplayed / 2)) : 1
      
      if (value + this.pagesDisplayed > this.maxPage) {
        value = this.maxPage - this.pagesDisplayed + 1
      }
      if (value < 1) {
        value = 1
      }
      return value
    },
    lastPageAfterCurrent() {
      let value = this._currentPage > this.pagesDisplayed ? this._currentPage + this._currentPage - this.firstPageBeforeCurrent - 1 : this.pagesDisplayed
      if (value > this.maxPage) {
        value = this.maxPage
      }
      return value
    },
    pagesArray() {
      let array = []
      for (let i = this.firstPageBeforeCurrent; i < this._currentPage; i++) {
        array.push(i)
      }
      for (let i = this._currentPage; i <= this.lastPageAfterCurrent; i++) {
        array.push(i)
      }
      return array
    },
  },

  methods: {
    getPageFromUrl() {
      let p = this.$route.query?.page
      if (!p || isNaN(Number(p))) {
        p = 1
      }
      return Number(p)
    },
    handlePrevious() {
      if (this._currentPage === 1) return
      this._currentPage -= 1
    },
    handleNext() {
      if (this._currentPage === this.maxPage) return
      this._currentPage += 1
    },
    handlePageClicked(p) {
      this._currentPage = p
    }
  }
}
</script>

<style></style>
