<template>
  <div class="card-header">

    <div class="input-group input-group-flush input-group-merge input-group-reverse">
      <span class="input-group-text">
        <button class="btn btn-sm btn-link text-muted" @click="onSearch">
          <i class="fa fa-search"></i>
        </button>
      </span>
      <input spellcheck="false" class="form-control list-search" v-model.trim="searchString" type="text"
        placeholder="Pesquisar" v-on:keyup.enter="onSearch" />
      <span class="input-group-text">
      </span>
    </div>

    <slot name="bulkAction"></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      typingTimer: null,
      searchString: ''
    }
  },
  mounted() { },
  methods: {
    onSearch() {
      this.$emit('onSearch', this.searchString)
    },
    setValue(value) {
      this.searchString = value
    }
  }
}
</script>

<style></style>
