<template>
  <LoadingContainer v-if="loadingItems" />
  <template v-else>
    <div class="row">
      <div class="cursor-pointer col-6 col-lg-4 align-items-center d-flex" v-for="(item, index ) in filteredItems"
        :key="index" @click="handleItemClicked(item)">
        <div class="flex-1 text-center rounded-4  p-5  my-3 border"
          :class="{ 'bg-primary-soft': selectedItems.find(elem => elem.id == item.id) }">
          <h5 class="m-0">
            Linha<br><span class="fw-bold">
              {{ item.name.capitalizeFirst() }}
            </span>
          </h5>
        </div>
      </div>
    </div>
  </template>
</template>

<script lang="ts">

import ListControllerMixin from '@/mixins/ListControllerMixin';
import { searchString } from '@/utils/Helper';
import { defineComponent } from 'vue';

export default defineComponent({
  mixins: [ListControllerMixin],
  emits: ['itemsChanged'],
  props: {
    col: {
      type: String,
      default: 'col-10'
    }
  },
  mounted() {
  },
  watch: {
    search(newValue) {
      this.searchValue(newValue)
    }
  },
  data() {
    return {
      items: [] as any[],
      selectedItems: [] as any[],
      search: ''
    }
  },
  computed: {
    filteredItems(): any[] {
      return this.items.filter((elem: any) => !elem.hidden)
    }
  },
  methods: {
    fetchPromise() {
      return this.$productService.listLines()
    },
    searchValue(value: string) {
      const it = searchString(this.items, value, 'name')
      this.items = JSON.parse(JSON.stringify(it))
    },
    handleItemClicked(item: any) {
      const index = this.selectedItems.findIndex((elem: any) => elem.id == item.id)
      if (index > -1) {
        this.selectedItems.splice(index, 1)
      } else {
        this.selectedItems.push(item)
      }
      this.$emit('itemsChanged', JSON.parse(JSON.stringify(this.selectedItems)))
    },
  }
})
</script>

<style></style>
