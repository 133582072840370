<template>
  <nav class="navbar navbar-expand-sm navbar-light bg-secondary fixed-top" id="topbar">

    <div class="container not-gutter-fix">
      <router-link :to="$paths.home" class="navbar-brand">
        <img src="@/assets/img/navlogo.png" alt="">
        <!-- <small class="text-muted">{{ $appString }}</small> -->
      </router-link>
      <template v-if="!userSession && hasMenu">
        <div class="collapse navbar-collapse" id="navbarSupportedContent" v-if="hasEnterButton">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <router-link active-class="router-link-active" :to="$paths.login">
              <li class="nav-item">
                <a class="text-paragraph btn btn-primary text-white fw-bold">Entrar</a>
              </li>
            </router-link>
          </ul>
        </div>
      </template>
      <template v-else-if="hasMenu">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <router-link v-for="link in links" active-class="router-link-active" :to="link.to">
              <li class="nav-item">
                <a class="nav-link text-paragraph">{{ link.title }}</a>
              </li>
            </router-link>
            <li class="nav-item d-block d-sm-none" @click.prevent="logout">
              <a class="nav-link text-paragraph text-white cursor-pointer">Sair</a>
            </li>
          </ul>
          <div class="d-flex align-items-center d-none d-sm-flex">
            <span class="ps-3 pe-3 text-paragraph text-muted" v-if="links.length">|</span>
            <div class="dropdown cursor-pointer align-items-center d-flex" @mouseover="avatarOver"
              @mouseleave="avatarLeave">
              <span id="text-user-name" class="me-3 fw-bold text-golden">{{ userSession?.name }}</span>
              <a href="#" class="avatar avatar-xs dropdown-toggle">
                <div
                  class="avatar-img rounded-circle bg-secondary align-items-center avatar-img bg-golden d-flex display-6 justify-content-center rounded-circle text-white">
                  <span class="fas fa-user fa-medium-size text-primary"></span>
                </div>
              </a>
              <div class="dropdown-menu dropdown-menu-end cursor-pointer">
                <span @click="logout" class="dropdown-item text-danger">Sair</span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </nav>
</template>

<script>
import { useMainStore } from '@/store'
import $ from 'jquery'
import { storeToRefs } from 'pinia';
export default {
  name: 'Navbar',
  props: {
    link: String,
    linkName: String,
    sublink: String,
    sublinkName: String,
    current: String
  },
  setup() {
    const store = useMainStore()
    const { session } = storeToRefs(store)
    return {
      userSession: session,
    }
  },
  mounted() {
  },
  computed: {
    hasEnterButton() {
      return this.$route.path !== this.$paths.login // && this.$route.path !== this.$paths.register
    },
    hasMenu() {
      return this.$route.name !== 'Product Detail'
    }

  },
  data() {
    return {
      links: [
        // {
        //   to: this.$paths.appointments.list,
        //   title: 'Minhas sessões'
        // },
        // {
        //   to: this.$paths.chats,
        //   title: 'Chats'
        // },
        // {
        //   to: this.$paths.more,
        //   title: 'Mais'
        // },
      ]
    }
  },
  methods: {
    async logout() {
      this.$router.push(this.$paths.login)
      // try {
      //   await this.$userService.logout()
      // } catch (error) {
      //   console.log('ERRO NO LOGOUT', error)
      // } finally {
      // }
    },
    avatarOver() {
      $('.dropdown-menu').addClass('show')
      $('.dropdown-menu').attr('data-bs-popper', true)
    },
    avatarLeave() {
      $('.dropdown-menu').removeClass('show')
      $('.dropdown-menu').removeAttr('data-bs-popper')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.navbar-brand>img {
  max-width: 100%;
  max-height: 3.5rem;
}
</style>
