import { ILogin } from "@/interfaces";
import { useMainStore } from "@/store";
import NetworkService, { Endpoint } from "./NetworkService";
import { fakeSession, fakeCategoryList } from '@/utils/fakeData'
export default class UserService extends NetworkService {

  constructor() {
    super();
  }
  async login(params: ILogin) {
    var login = await this.post(Endpoint.login, params);
    if (login.status) {
      const store = useMainStore()
      store.saveAuthToken(login.access_token || '')
      let userData = await this.prepareUserData(login.data.id);
      store.saveSession(userData)
      return userData;
    } else {
      throw { message: login.message || "Erro ao fazer login" };
    }
  }

  async prepareUserData(userId: any) {
    const res = await this.get(`${Endpoint.users}/${userId}`);
    return res.data;
  }
  async register(params: any) {
    const store = useMainStore()
    if (params.redirectTo) {
      delete (params.redirectTo)
    }
    const res: any = await this.post(Endpoint.users, params);
    if (res.status) {
      store.saveAuthToken(res.access_token)
      let userData = await this.prepareUserData(res.data.id);
      store.saveSession(userData)
      return userData
    } else {
      throw { message: res.message || "Erro ao criar conta" };
    }
  }
  savePreferences(lines: any[], secret_santa_id: string) {
    return this.post(`${Endpoint.secret_santas}/${secret_santa_id}/preferences`, { lines });
  }
  sendRecoveryPasswordEmail(email: string) {
    return this.post(Endpoint.recoveryPassword, { email });
  }
  listCategories() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          status: true,
          data: fakeCategoryList
        })
      }, 1000);
    })
  }
  listGroups() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          status: true,
          data: fakeCategoryList
        })
      }, 1000);
    })
  }

  getFormattedUser(item: any) {
    try {

    } catch (e) {
      throw (e)
    } finally {
      return item;
    }
  }

  resetPassword(params: any) {
    return this.post(`${Endpoint.passwordReset}`, params);
  }
}
