<template>
  <div class="form-group">
    <div class="d-flex justify-content-between">
      <label v-if="label" class="form-label text-muted"> {{ label }} <i v-if="required" class="text-danger">*</i>
      </label>
      <div class="col-auto">
        <!-- <span v-if="submitting" class="fa-solid fa-circle-notch fa-spin"></span> -->
      </div>
      <slot name="labelContentEnd"></slot>
    </div>
    <input class="form-control" v-model.trim="model.$model" :class="{ 'is-invalid': model.$error }" type="text" v-maska
      :data-maska="'#####-###'" :placeholder="placeholder" :style="inputStyle" @input="zipCodeChanged" />
    <div class="invalid-feedback">
      <small>{{ errorMessage }}</small>
    </div>
    <slot name="content"></slot>
  </div>
</template>
<script lang="ts">
import { vMaska } from "maska"
import { defineComponent } from "vue"
export interface IZipCodeAddressResponse {
  street: string
  neighborhood: string,
  city: string,
  uf: string,
}

export const getAddressFromCep = async (zipcode: string): Promise<IZipCodeAddressResponse> => {
  let res: any = await fetch(`https://viacep.com.br/ws/${zipcode.replace('-', '')}/json`)
  res = await res.json()
  if (res.erro) {
    throw ('Cep inválido')
  } else {
    return {
      street: res.logradouro,
      neighborhood: res.bairro,
      city: res.localidade,
      uf: res.uf,
    }
  }
}
export default defineComponent({
  name: 'ZipCodeInput',
  emits: ['onAddressError', 'onAddressSuccess'],
  directives: {
    maska: vMaska
  },
  props: {
    model: {
      type: Object,
      required: false,
      default: () => { }
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    errorMsg: {
      type: String,
      default: 'Por favor, preencha este campo corretamente'
    },
    inputStyle: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      submitting: false
    }
  },
  computed: {
    errorMessage(): string {
      return this.model.$errors
        ? this.model.$errors.find((e: any) => e)?.$message
        : this.errorMsg;
    },
  },
  methods: {
    async zipCodeChanged(event: any) {
      // try {
      //   const zipcode = event.target.value
      //   if (!zipcode) return
      //   if (zipcode.length === 9) {
      //     this.submitting = true
      //     let res: any = await fetch(`https://viacep.com.br/ws/${zipcode.replace('-', '')}/json`)
      //     res = await res.json()
      //     console.log('response', res)
      //     if (res.erro) {
      //       this.$emit('onAddressError')
      //     } else {
      //       this.$emit('onAddressSuccess', {
      //         street: res.logradouro,
      //         neighborhood: res.bairro,
      //         city: res.localidade,
      //         uf: res.uf,
      //       })
      //     }
      //   }
      // } catch (e: any) {
      //   console.log(e)
      //   this.$eventBus.$emit('showErrorToast', { msg: e.message })
      // } finally {
      //   this.submitting = false
      // }
    }
  }
})
</script>
